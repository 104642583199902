import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Test Week`}</em></strong></p>
    <p>{`Push Press 5×1 to a 1RM`}</p>
    <p>{`Pendlay Rows 5×2 to a 2RM`}</p>
    <p>{`then,`}</p>
    <p>{`30-Push Press (135/95)`}</p>
    <p>{`1000M Row`}</p>
    <p>{`30-Push Jerk (135/95)`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p">{`Bonus WOD: `}</strong></p>
    <p>{`45-30-15 reps or seconds each of:`}</p>
    <p>{`GHD Situps`}</p>
    <p>{`Back Extensions`}</p>
    <p>{`L-Sit Hold`}</p>
    <p>{`Plank`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 10/22/18`}</em></p>
    <p><strong parentName="p">{`*`}{`The Granite Games Throwdown in coming up January 12th.  Get your
teams together and registered soon to reserve your spot!  December 17th
is the last day to register and get your Granite Games shirt on the day
of the competition. If you’d like to be on a team but don’t have 2 other
teammates please put your name on the white board in back and we’ll get
you on a team. `}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://competitioncorner.net/events/1549?fbclid=IwAR0ST57-McRs4RyVzEL5D6sr_LV403-KCqTNNEKweUXuq32ASGMdLOHFY4s#.W_biri3Mz1w"
      }}>{`https://competitioncorner.net/events/1549?fbclid=IwAR0ST57-McRs4RyVzEL5D6sr_LV403-KCqTNNEKweUXuq32ASGMdLOHFY4s#.W_biri3Mz1w`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      